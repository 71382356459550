import { combineReducers } from 'redux';

import errors from './errors';
import payingMember from './payingMember';

import {
  ACTION_LOAD_DASHBOARD_DATA__SUCCESS,
  ACTION_LOAD_STATISTICS__SUCCESS,
} from '../actionTypes';


const shortStats = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_STATISTICS__SUCCESS:
      return action.payload.statistics;

    default:
      return state;
  }
};

const dashboardData = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_DASHBOARD_DATA__SUCCESS:
      return action.payload.dashboardData;

    default:
      return state;
  }
};

export default combineReducers({
  shortStats,
  dashboardData,
  payingMember,
  errors,
});
