import { combineReducers } from 'redux';

import loadDashboardDataError from './loadDashboardDataError';
import loadPayingMembersError from './loadPayingMembersError';
import loadStatisticsError from './loadStatisticsError';


export default combineReducers({
  loadDashboardDataError,
  loadPayingMembersError,
  loadStatisticsError,
});
