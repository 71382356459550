import _difference from 'lodash/difference';
import _pick from 'lodash/pick';


export const BALANCE_ADJUSTMENT_TYPE__DEFAULT = 'default';
export const BALANCE_ADJUSTMENT_TYPE__POLLBONUS = 'pollbonus';
export const BALANCE_ADJUSTMENT_TYPE__REWARD = 'reward';
export const BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_CREDITS_BONUS = 'site-event-credits-bonus';
export const BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_REVENUE_BONUS = 'site-event-revenue-bonus';
export const BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_DEPOSIT_BONUS = 'site-event-deposit-bonus';
export const BALANCE_ADJUSTMENT_TYPE__WEEKLY_EVENT_DEPOSIT_BONUS = 'weekly-event-deposit-bonus';
export const BALANCE_ADJUSTMENT_TYPE__WELCOME_DEPOSIT_BONUS = 'welcome-deposit-bonus';

export const balanceAdjustmentTypes = [
  BALANCE_ADJUSTMENT_TYPE__DEFAULT,
  BALANCE_ADJUSTMENT_TYPE__POLLBONUS,
  BALANCE_ADJUSTMENT_TYPE__REWARD,
  BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_CREDITS_BONUS,
  BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_REVENUE_BONUS,
  BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_DEPOSIT_BONUS,
  BALANCE_ADJUSTMENT_TYPE__WEEKLY_EVENT_DEPOSIT_BONUS,
  BALANCE_ADJUSTMENT_TYPE__WELCOME_DEPOSIT_BONUS,
];

export const balanceAdjustmentTypeLangKeys = {
  [BALANCE_ADJUSTMENT_TYPE__DEFAULT]: 'msg.balance-adjustment-type.default',
  [BALANCE_ADJUSTMENT_TYPE__POLLBONUS]: 'msg.balance-adjustment-type.pollbonus',
  [BALANCE_ADJUSTMENT_TYPE__REWARD]: 'msg.balance-adjustment-type.reward',
  [BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_CREDITS_BONUS]: 'msg.balance-adjustment-type.site-event-credits-bonus',
  [BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_REVENUE_BONUS]: 'msg.balance-adjustment-type.site-event-revenue-bonus',
  [BALANCE_ADJUSTMENT_TYPE__SITE_EVENT_DEPOSIT_BONUS]: 'msg.balance-adjustment-type.site-event-deposit-bonus',
  [BALANCE_ADJUSTMENT_TYPE__WEEKLY_EVENT_DEPOSIT_BONUS]: 'msg.balance-adjustment-type.weekly-event-deposit-bonus',
  [BALANCE_ADJUSTMENT_TYPE__WELCOME_DEPOSIT_BONUS]: 'msg.balance-adjustment-type.welcome-deposit-bonus',
};

export const LOSS_COMPENSATION_TYPE__COMPENSATION = 'compensation';
export const LOSS_COMPENSATION_TYPE__LOSS = 'loss';

export const lossCompensationTypes = [
  LOSS_COMPENSATION_TYPE__COMPENSATION,
  LOSS_COMPENSATION_TYPE__LOSS,
];

export const lossCompensationTypeLangKeys = {
  [LOSS_COMPENSATION_TYPE__COMPENSATION]: 'msg.loss-compensation-type.compensation',
  [LOSS_COMPENSATION_TYPE__LOSS]: 'msg.loss-compensation-type.loss',
};

export const LOSS_COMPENSATION_CAUSE__DEPOSIT = 'deposit';
export const LOSS_COMPENSATION_CAUSE__REVENUE = 'revenue';
export const LOSS_COMPENSATION_CAUSE__VOIDSALERETURN = 'voidsalereturn';
export const LOSS_COMPENSATION_CAUSE__CANCELED_SHOP_PURCHASE = 'canceled-shop-purchase';
export const LOSS_COMPENSATION_CAUSE__REVENUE_ADJUST = 'revenue-adjust';
export const LOSS_COMPENSATION_CAUSE__CANCELED_PAYOUT = 'canceled-payout';
export const LOSS_COMPENSATION_CAUSE__DECLINED_PAYOUT = 'declined-payout';
export const LOSS_COMPENSATION_CAUSE__CREDITS_ADJUST = 'credits-adjust';
export const LOSS_COMPENSATION_CAUSE__VOIDSALE = 'voidsale';
export const LOSS_COMPENSATION_CAUSE__CHARGEBACK = 'chargeback';
export const LOSS_COMPENSATION_CAUSE__OVERDRAFT = 'overdraft';
export const LOSS_COMPENSATION_CAUSE__FEE = 'fee';

export const lossCompensationCauses = [
  LOSS_COMPENSATION_CAUSE__DEPOSIT,
  LOSS_COMPENSATION_CAUSE__REVENUE,
  LOSS_COMPENSATION_CAUSE__VOIDSALERETURN,
  LOSS_COMPENSATION_CAUSE__CANCELED_SHOP_PURCHASE,
  LOSS_COMPENSATION_CAUSE__REVENUE_ADJUST,
  LOSS_COMPENSATION_CAUSE__CANCELED_PAYOUT,
  LOSS_COMPENSATION_CAUSE__DECLINED_PAYOUT,
  LOSS_COMPENSATION_CAUSE__CREDITS_ADJUST,
  LOSS_COMPENSATION_CAUSE__VOIDSALE,
  LOSS_COMPENSATION_CAUSE__CHARGEBACK,
  LOSS_COMPENSATION_CAUSE__OVERDRAFT,
  LOSS_COMPENSATION_CAUSE__FEE,
];

export const lossCompensationCauseLangKeys = {
  [LOSS_COMPENSATION_CAUSE__DEPOSIT]: 'msg.loss-compensation-cause.deposit',
  [LOSS_COMPENSATION_CAUSE__REVENUE]: 'msg.loss-compensation-cause.revenue',
  [LOSS_COMPENSATION_CAUSE__VOIDSALERETURN]: 'msg.loss-compensation-cause.voidsalereturn',
  [LOSS_COMPENSATION_CAUSE__CANCELED_SHOP_PURCHASE]: 'msg.loss-compensation-cause.canceled-shop-purchase',
  [LOSS_COMPENSATION_CAUSE__REVENUE_ADJUST]: 'msg.loss-compensation-cause.revenue-adjust',
  [LOSS_COMPENSATION_CAUSE__CANCELED_PAYOUT]: 'msg.loss-compensation-cause.canceled-payout',
  [LOSS_COMPENSATION_CAUSE__DECLINED_PAYOUT]: 'msg.loss-compensation-cause.declined-payout',
  [LOSS_COMPENSATION_CAUSE__CREDITS_ADJUST]: 'msg.loss-compensation-cause.credits-adjust',
  [LOSS_COMPENSATION_CAUSE__VOIDSALE]: 'msg.loss-compensation-cause.voidsale',
  [LOSS_COMPENSATION_CAUSE__CHARGEBACK]: 'msg.loss-compensation-cause.chargeback',
  [LOSS_COMPENSATION_CAUSE__OVERDRAFT]: 'msg.loss-compensation-cause.overdraft',
  [LOSS_COMPENSATION_CAUSE__FEE]: 'msg.loss-compensation-cause.fee',
};

export const SALE_TYPE__EMAIL = 'email';
export const SALE_TYPE__MOBILE = 'mobile';
export const SALE_TYPE__ICQ = 'icq';
export const SALE_TYPE__MSN = 'msn';
export const SALE_TYPE__YAHOO = 'yahoo';
export const SALE_TYPE__AIM = 'aim';
export const SALE_TYPE__OTHER = 'other';
export const SALE_TYPE__WEBCAM = 'webcam';
export const SALE_TYPE__UNDERWEAR = 'underwear';
export const SALE_TYPE__PRIVATE_PHOTO = 'photo';
export const SALE_TYPE__PRIVATE_VIDEO = 'video';
export const SALE_TYPE__MESSAGE = 'msg';
export const SALE_TYPE__LIVESHOW = 'liveshow';
export const SALE_TYPE__AFFUSER = 'affuser';
export const SALE_TYPE__AFFSELLER = 'affseller';
export const SALE_TYPE__POLLVOTE = 'pollvote';
export const SALE_TYPE__EROTIC_STORY = 'eroticstory';
export const SALE_TYPE__VOIP = 'voip';
export const SALE_TYPE__SCOPO = 'scopo';
export const SALE_TYPE__RIVGIRLSVOTE = 'rivgirlsvote';
export const SALE_TYPE__C6 = 'c6';
export const SALE_TYPE__SKYPE = 'skype';
export const SALE_TYPE__VOTE = 'vote';
export const SALE_TYPE__SMS = 'sms';
export const SALE_TYPE__PROFILE_PHOTO = 'profile-photo';
export const SALE_TYPE__MODEL_GIFT = 'model-gift';
export const SALE_TYPE__REAL_GIFT = 'real-gift';
export const SALE_TYPE__FACEBOOK = 'facebook';
export const SALE_TYPE__TIPS = 'tips';
export const SALE_TYPE__CHATMSG = 'chatmsg';
export const SALE_TYPE__MSG_WITH_ATTACH = 'msg-with-attach';
export const SALE_TYPE__INSTAGRAM = 'instagram';
export const SALE_TYPE__PRESENTATION = 'presentation';
export const SALE_TYPE__CHATSESSION = 'chatsession';
export const SALE_TYPE__BLUECHECK_POINTS = 'bluecheck-points';

export const saleTypes = [
  SALE_TYPE__EMAIL,
  SALE_TYPE__MOBILE,
  SALE_TYPE__ICQ,
  SALE_TYPE__MSN,
  SALE_TYPE__YAHOO,
  SALE_TYPE__AIM,
  SALE_TYPE__OTHER,
  SALE_TYPE__WEBCAM,
  SALE_TYPE__UNDERWEAR,
  SALE_TYPE__PRIVATE_PHOTO,
  SALE_TYPE__PRIVATE_VIDEO,
  SALE_TYPE__MESSAGE,
  SALE_TYPE__LIVESHOW,
  SALE_TYPE__AFFUSER,
  SALE_TYPE__AFFSELLER,
  SALE_TYPE__POLLVOTE,
  SALE_TYPE__EROTIC_STORY,
  SALE_TYPE__VOIP,
  SALE_TYPE__SCOPO,
  SALE_TYPE__RIVGIRLSVOTE,
  SALE_TYPE__C6,
  SALE_TYPE__SKYPE,
  SALE_TYPE__VOTE,
  SALE_TYPE__SMS,
  SALE_TYPE__PROFILE_PHOTO,
  SALE_TYPE__MODEL_GIFT,
  SALE_TYPE__REAL_GIFT,
  SALE_TYPE__FACEBOOK,
  SALE_TYPE__TIPS,
  SALE_TYPE__CHATMSG,
  SALE_TYPE__MSG_WITH_ATTACH,
  SALE_TYPE__INSTAGRAM,
  SALE_TYPE__PRESENTATION,
  SALE_TYPE__CHATSESSION,
  SALE_TYPE__BLUECHECK_POINTS,
];

export const visibleSaleTypes = _difference(saleTypes, [
  SALE_TYPE__POLLVOTE,
  SALE_TYPE__VOIP,
  SALE_TYPE__RIVGIRLSVOTE,
  SALE_TYPE__C6,
  SALE_TYPE__VOTE,
  SALE_TYPE__PROFILE_PHOTO,
  SALE_TYPE__CHATMSG,
  SALE_TYPE__PRESENTATION,
  SALE_TYPE__CHATSESSION,
  SALE_TYPE__BLUECHECK_POINTS,
]);

export const saleTypeLangKeys = {
  [SALE_TYPE__EMAIL]: 'msg.sale-type.email',
  [SALE_TYPE__MOBILE]: 'msg.sale-type.mobile',
  [SALE_TYPE__ICQ]: 'msg.sale-type.icq',
  [SALE_TYPE__MSN]: 'msg.sale-type.msn',
  [SALE_TYPE__YAHOO]: 'msg.sale-type.yahoo',
  [SALE_TYPE__AIM]: 'msg.sale-type.aim',
  [SALE_TYPE__OTHER]: 'msg.sale-type.other',
  [SALE_TYPE__UNDERWEAR]: 'msg.sale-type.underwear',
  [SALE_TYPE__WEBCAM]: 'msg.sale-type.webcam',
  [SALE_TYPE__PRIVATE_PHOTO]: 'msg.sale-type.private-photo',
  [SALE_TYPE__PRIVATE_VIDEO]: 'msg.sale-type.private-video',
  [SALE_TYPE__MESSAGE]: 'msg.sale-type.message',
  [SALE_TYPE__EROTIC_STORY]: 'msg.sale-type.eroticstory',
  [SALE_TYPE__LIVESHOW]: 'msg.sale-type.liveshow',
  [SALE_TYPE__AFFUSER]: 'msg.sale-type.affuser',
  [SALE_TYPE__AFFSELLER]: 'msg.sale-type.affseller',
  [SALE_TYPE__POLLVOTE]: 'msg.sale-type.pollvote',
  [SALE_TYPE__VOIP]: 'msg.sale-type.voip',
  [SALE_TYPE__SCOPO]: 'msg.sale-type.scopo',
  [SALE_TYPE__RIVGIRLSVOTE]: 'msg.sale-type.rivgirlsvote',
  [SALE_TYPE__C6]: 'msg.sale-type.c6',
  [SALE_TYPE__SKYPE]: 'msg.sale-type.skype',
  [SALE_TYPE__VOTE]: 'msg.sale-type.vote',
  [SALE_TYPE__SMS]: 'msg.sale-type.sms',
  [SALE_TYPE__PROFILE_PHOTO]: 'msg.sale-type.profile-photo',
  [SALE_TYPE__MODEL_GIFT]: 'msg.sale-type.model-gift',
  [SALE_TYPE__REAL_GIFT]: 'msg.sale-type.real-gift',
  [SALE_TYPE__FACEBOOK]: 'msg.sale-type.facebook',
  [SALE_TYPE__TIPS]: 'msg.sale-type.tips',
  [SALE_TYPE__CHATMSG]: 'msg.sale-type.chatmsg',
  [SALE_TYPE__MSG_WITH_ATTACH]: 'msg.sale-type.msg-with-attach',
  [SALE_TYPE__INSTAGRAM]: 'msg.sale-type.instagram',
  [SALE_TYPE__PRESENTATION]: 'msg.sale-type.presentation',
  [SALE_TYPE__CHATSESSION]: 'msg.sale-type.chatsession',
  [SALE_TYPE__BLUECHECK_POINTS]: 'msg.sale-type.bluecheck-points',
};

export const visibleSaleTypeLangKeys = _pick(saleTypeLangKeys, visibleSaleTypes);

export const SALE_STATUS__PENDING = 'pending';
export const SALE_STATUS__APPROVED = 'approved';
export const SALE_STATUS__CANCELED = 'canceled';
export const SALE_STATUS__VOIDED = 'voided';

export const saleStatuses = [
  SALE_STATUS__PENDING,
  SALE_STATUS__APPROVED,
  SALE_STATUS__CANCELED,
  SALE_STATUS__VOIDED,
];

export const saleStatusLangKeys = {
  [SALE_STATUS__PENDING]: 'msg.status.pending',
  [SALE_STATUS__APPROVED]: 'msg.status.approved',
  [SALE_STATUS__CANCELED]: 'msg.status.canceled',
  [SALE_STATUS__VOIDED]: 'msg.status.voided',
};

export const SERVICE_TYPE__WEBCAM = 'webchat';
export const SERVICE_TYPE__RIVPHONE = 'rivphone';
export const SERVICE_TYPE__BLUECHECK = 'bluecheck';

export const serviceTypes = [
  SERVICE_TYPE__WEBCAM,
  SERVICE_TYPE__RIVPHONE,
  SERVICE_TYPE__BLUECHECK,
];

export const serviceTypeLangKeys = {
  [SERVICE_TYPE__WEBCAM]: 'msg.service.webchat',
  [SERVICE_TYPE__RIVPHONE]: 'msg.service.rivphone',
  [SERVICE_TYPE__BLUECHECK]: 'msg.service.bluecheck',
};

export const REVENUE_CONVERSION_STATUS__PENDING = 'pending';
export const REVENUE_CONVERSION_STATUS__APPROVED = 'approved';
export const REVENUE_CONVERSION_STATUS__CANCELED = 'canceled';

export const revenueConversionStatuses = [
  REVENUE_CONVERSION_STATUS__PENDING,
  REVENUE_CONVERSION_STATUS__APPROVED,
  REVENUE_CONVERSION_STATUS__CANCELED,
];

export const revenueConversionStatusLangKeys = {
  [REVENUE_CONVERSION_STATUS__PENDING]: 'msg.status.pending',
  [REVENUE_CONVERSION_STATUS__APPROVED]: 'msg.status.approved',
  [REVENUE_CONVERSION_STATUS__CANCELED]: 'msg.status.canceled',
};
