import { stripLangFromUri } from '../../../core/i18n/utils';
import { get182257Url, getTermsUrl, getPrivacyUrl } from '../../utils/url';

import { getShowAdultWarning } from '../../selectors/adultWarning';

import { actionShowAdultWarningDialog } from '../../actions/dialogs/adultWarningDialog';


export default ({ getState, dispatch }) => (
  function showAdultWarningHandler(nextState, successCallback, errorCallback) {
    if (
      getShowAdultWarning(getState())
      && ![get182257Url(), getTermsUrl(), getPrivacyUrl()].includes(
        stripLangFromUri(nextState.location.pathname)
      )
    ) {
      dispatch(actionShowAdultWarningDialog());
    }

    successCallback();
  }
);
